import React from 'react';

// Components
import { HeroAsym, HeroSecondary, EmptyHero } from 'components/core/hero';

// Hooks
import { useTranslation } from 'gatsby-plugin-react-i18next';

export function Hero(){
  const {t} = useTranslation("career");
  return <HeroAsym title={t("title")} subtitle={t("subtitle")}/>
}

export function CaseHero({title}){
  return <HeroSecondary title={title}/>
}
export {EmptyHero};
