import React from 'react';

// Components
import { Link } from 'gatsby';
import { WhyChooseUse } from 'components/careers/why';
import { ConcaveCurve } from 'components/core/curves';

function Card({node}){
  return <Link className="bg-slate-100 md:aspect-square rounded-md scale-100 hover:scale-[1.02] transition-transform overflow-hidden" to={node.fields.path}>
          <div className=" flex flex-col h-full justify-between p-3 lg:p-6">
            <p className="text-right text-slate-500">{node.frontmatter.stay}</p>
            <div>
              <h2 className="font-medium text-xl">{node.frontmatter.title}</h2>
              <p className="text-slate-800">{node.frontmatter.location}</p>
            </div>
          </div>
        </Link>
}

export function OfferList({values}){
  return <div className="relative ">
          <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 mx-auto max-w-7xl px-6 lg:px-3 gap-6 my-6">
            {values.map(d=><Card key={d.id} {...d}/>)}
          </div>
          <WhyChooseUse/>
          <div className="h-1/2 w-full bg-white lg:pt-16"/>
          <div className="h-1/2  w-full absolute bottom-0">
            <ConcaveCurve color="text-[#354178]"/>
          </div>
        </div>
}